@import '../../theme';

.wrapper {
    top: 50%;
    left: 50%;
    width: 100%;
    font-size: size(7);
    color: #bfc0c2;
    text-align: center;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);

    & > p:first-child {
        margin-bottom: size(6);
    }

    & img {
        width: 50%;
        max-width: size(290);
    }
    
    & i {
        font-size: size(5);
        margin: 0 size(3);
    }
}