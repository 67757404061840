$base_size_unit: 0.25rem;

$types-pallet: 4 "blue", 5 "green", 6 "red", 7 "purple", 8 "orange", 9 "turquoise", 10 "yellow";

$opoint-blue: #78acd7;
$opoint-shade-blue: #3e7eb3;
$opoint-gray: #6f747d;
$opoint-green: #67c897;
$opoint-dark-green: darken($opoint-green, 10%);
$opoint-header-icons-gray: #9F9F9F;
$opoint-hover-gray: #b7b8bc;
$opoint-hover-dark-gray: darken(#878b9c, 15%);
$opoint-bg-gray: #fafafa;
$opoint-shadow: rgba(0, 0, 0, 0.2);
$opoint-warning-red: #f26d7d;

$icomoon-font-family: "opoint" !default;