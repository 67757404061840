.wrapper {
  flex: 1;
  position: relative;
  border-right: 1px solid #e5e8ec;
  width: 100%;
  max-width: 100%;
}

.content {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(5, calc(18% - 6px));
  grid-auto-columns: calc(18%);
  grid-template-rows: minmax(50px, 1fr);
  height: 100%;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;

  scrollbar-width: none; // firefox 64+
  -ms-overflow-style: none; /* IE 11 */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }

  li:not(:last-child) {
    height: 100%;
    border-right: 1px solid #e5e8ec;
    grid-area: span 1 / span 1;
  }
}

.hidden {
  display: none;
}

.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  background: white;
  color: black;
  border: none;
}

.rightPaddle {
  right: 0;
  border-left: 1px solid #e5e8ec;
}

.leftPaddle {
  left: 0;
  border-right: 1px solid #e5e8ec;
}
