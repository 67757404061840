@function size($size) {
    @return $size * $base_size_unit;
}

@function op-pallet($colorName) {
    $colorName: inspect($colorName);
  
    @if $colorName == "red" {
      @return #ed1c24;
  
    } @else if $colorName == "orange" {
      @return #f26522;
  
    } @else if $colorName == "yellow" {
      @return #ffc219;
  
    } @else if $colorName == "green" {
      @return #39b54a;
  
    } @else if $colorName == "turquoise" {
      @return #00a99d;
  
    } @else if $colorName == "blue" {
      @return #0076a3;
  
    } @else if $colorName == "darkblue" {
      @return #1b1464;
  
    } @else if $colorName == "purple" {
      @return #662d91;
  
    } @else if $colorName == "pink" {
      @return #ec008c;
  
    } @else {
      @return black;
    }
  }