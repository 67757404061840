@import '../../theme';

.sortableTree{
    min-height: size(100);
    margin-bottom: size(10);

    .label{
        font-size: size(5);
        font-weight: normal;
    }
}

.actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}