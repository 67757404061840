@import '../../theme';

.list{
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;

    li{
        margin: size(2) size(4) size(2) 0;
        padding: 0;
    }
}

.dnditem{
    cursor: grab;
    position: relative;

    & .dragIcon{
        margin-left: size(4);
        font-size: size(4);
    }

    &.isDragging{
        opacity: 0.5;
    }

    & > div{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: size(3);
        border: 1px solid #ededed;
        border-radius: size(1);
        transition: all .2s ease-in-out;
        background-color: #f8f8f8;
    }

    &.wrapperIsOver > div {
        transform: translateY(-120%);
    }

    &.wrapperIsOver::before {
        content: '';
        position: absolute;
        left: 0;
        border: size(1) dashed rgba(gray, 0.4);
        width: 100%;
        top: 0;
        height: 100%;
    }
}