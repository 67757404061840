@import '../theme';

.list{
    list-style: none;
    padding: 0;

    li{
        margin: size(2) 0;
    }

    li a{
        font-size: size(5);
    }
}