@import '../../theme';

.wrapper {
    width: 100%;
    text-align: center;
    margin-top: size(43);

    & p {
        font-size: size(7);
        padding-top: size(13);
        color: '#001f4a'
    }
}