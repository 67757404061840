@import '../../theme';

$radio-dimensions: size(5);

.wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 0;
    font-size: size(5);
    font-weight: normal;

    &.disabled{
        cursor: not-allowed;
        opacity: 0.44;
    }
}

.radioInput{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: size(2);

    & > input{
        opacity: 0;
        height: 0;
        width: 0;
    }

    & > .radioControl{
        display: flex;
        justify-content: center;
        align-items: center;
        width: $radio-dimensions;
        height: $radio-dimensions;
        background-color: white;
        border-radius: $radio-dimensions;
        margin: 0;
        box-sizing: content-box;
        border: size(1) solid rgba(200, 200, 200, 0.41);
    }

    & > .radioControl:after {
        content: '';
        display: block;
        opacity: 0;
        border-radius: 50%;
        width: size(4);
        height: size(4);
        background: $opoint-blue;
        transition: .1s all ease-in-out;
    }

    & > input:checked + .radioControl:after {
        opacity: 1;
    } 
    
    & > input:focus + .radioControl {
        box-shadow: 0 0 1px 1px black;
    } 
}