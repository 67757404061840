@import '../../theme';

.wrapper{
    list-style: none;
    padding: 0;
    margin: 0;

    & > li{
        background-color: #ededed;
        padding: size(4);
        margin-bottom: size(4);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:last-of-type{
            margin-bottom: 0;
        }

        h3{
            font-size: size(5);
            margin: 0;
            font-weight: 600;
            max-width: size(200);
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        p{
            margin: size(2) 0 0 0;
        }
    }

    .warningsWrapper {
        & > div {
            margin: size(2) 0 0 0;
        }
        ul {
            padding-left: 0;
            list-style: none;
        }

        ul li span {
            font-weight: bold;
        }
    }
}