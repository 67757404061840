@import '../../theme';

$checkbox-dimension: size(5);

.wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 0;
    font-size: size(5);
    font-weight: normal;

    &.delete .checkbox > input + span{
        background-color: #ffd8d2;
    }

    &.delete .checkbox > input:checked + span{
        background-color: #ff7e6c;
    }

    &:hover .checkbox > input:not(:checked) + span i{
        opacity: 1;
    }

    &.disabled{
        cursor: not-allowed;
        opacity: 0.44;
    }
}

.checkbox{
    width: $checkbox-dimension;
    height: $checkbox-dimension;
    position: relative;
    margin-right: size(2);

    & > input{
        opacity: 0;
        width: 0;
        height: 0;
    }

    .checkboxControl{
        transition: .1s all ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: $checkbox-dimension;
        height: $checkbox-dimension;
        top: 0;
        left: 0;
        background-color: #cfd1d5;
        border-radius: size(1);
    }

    .checkboxControl i{
        font-size: size(3);
        color: gray;
        opacity: 0;
        transition: all .2s ease-in-out;
    }

    & > input:checked + .checkboxControl{
        background-color: $opoint-blue;
    }

    & > input:focus + .checkboxControl{
        box-shadow: 0 0 1px 1px black;
    }
    
    & > input:checked + .checkboxControl i{
        opacity: 1;
        color: white;
    }
}