@import '../../theme';

.listItem {
  display: flex;
}

.wrapper {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding-left: size(8);
  margin-top: size(8);
  margin-bottom: size(8);
}

.title {
  color: #575b62;
  font-size: size(4);
  line-height: size(4);
  vertical-align: middle;
}

.content {
  color: #575b62;
  font-size: size(4);
  margin-top: size(2);
  display: flex;
  align-items: center;
}

.value {
  color: #575b62;
  font-size: size(4);
  line-height: size(6);
  vertical-align: middle;
  margin-left: size(2);
  font-weight: bold;
}

.countryFlag{
  font-size: size(4);
  line-height: size(6);
}