@import '../theme';

.profileEditor{
    margin-top: 0 !important;
    position: relative !important;
    margin-left: -10px;
    width: calc(100% + 10px) !important;
    margin-bottom: 15px;

    .filterPanel{
        max-height: 100%;
        overflow-y: visible;
    }

    .filterPanel .backButtonWrapper{
        margin-left: 0;
        padding-left: 5px;
    }
}

.filterPanel{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;

    &.topPadding{
        padding-top: 43px;
    }

    .backButtonWrapper{
        position: fixed;
        top: 10px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e5e7eb;
        margin: 0 15px;
        padding-bottom: 10px;
        background-color: white;
        height: 33px;
        z-index: 1;
    
        button{
            background-color: transparent;
            padding: 0;
            border: none;
            font-size: 13px;
            color: $opoint-blue;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            align-items: center;
        
            i{
                font-size: 10px;
                margin-right: 5px;
                transition: transform .1s ease-in-out;
            }
    
            &:hover i, &:focus i{
                transform: translateX(-2px);
            }
        }
    
        h3.titleWrapper {
            font-size: 18px;
            text-transform: none;
            margin: 0 0 0 15px;
            padding: 0;
        }
    }

    .filterColumn{
        width: 150px;
        margin: 0 15px;
        margin-bottom: 20px;

        &.width2{
            width: 330px;
        }

        &.width3{
            width: 510px;
        }
        
        .header{
            color: $opoint-gray;
            text-align: left;
            font-size: 1.1em;
            font-weight: 700;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 8px;
            margin-top: 5px;
            margin-bottom: 10px;
            border: none;
            border-bottom: 1px solid #e5e7eb;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background-color: transparent;

            .title{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }

        .header i{
            color: $opoint-hover-gray;
            font-size: 12px;
            margin-left: 5px;
        }

        .header:hover i, .header:focus i{
            color: $opoint-blue;
        }

        ul{
            display: flex;
            flex-direction: column;
            list-style: none;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
        }

        ul li{
            margin: 5px 0;
            margin-right: 20px;
            color: $opoint-gray;
            cursor: pointer;
            position: relative;
            width: 100%;
        }
    
        ul li > span {
            z-index: 1;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        ul li.selected:after{
            content: '';
            position: absolute;
            left: -5px;
            top: -2px;
            height: calc(100% + 4px);
            width: calc(100% + 10px);
            background-color: rgba($opoint-blue, 0.3);
            z-index: 0;
            border-radius: 5px;
            padding: 5px;
        }
    }
}