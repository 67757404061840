@import '../theme';

.wrapper{
    article h2{
        font-size: size(8);
    }
    article h3{
        font-size: size(6);
    }

    article ul li{
        font-size: size(5);
        margin: size(2) 0;
    }

    details{
        margin: size(2) 0;
    }

    details summary{
        font-size: size(5);
        cursor: pointer;
    }

    details ul li{
        font-size: size(5);
        margin: size(2) 0;
    }
}