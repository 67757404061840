@import '../theme';

.modalWrapper{
    hr{
        margin: size(10) 0;
    }
}

.actionsWrapper{
    margin: 0 0 size(10) 0;

    & > a{
        font-size: size(6);
    }

    .emailPhoneWrapper{
        h4{
            margin-right: size(10);
        }
    }
}

.videoWrapper{
    & > div:first-child{
        width: 30%;
    }
    & > div:last-child{
        width: 70%;
    }

    h4{
        margin: 0 0 size(6) 0;
    }

    ul{
        list-style: none;
        padding: 0;

        li{
            font-size: size(5);
            margin: size(2) 0;
        }

        li button{
            border: none;
            background: none;
            padding: 0;
            color: $opoint-shade-blue;
        }

        li button:hover, 
        li.active button{
            font-weight: bold;
        }
    }
}