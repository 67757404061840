@import '../../theme';

.overlay{
    z-index: 1100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.2);
    overflow-x: hidden;
    overflow-y: auto;
}

.modal{
    max-width: size(300);
    background-color: #f8f8f8;
    margin: size(10) auto;
    border-radius: size(1);
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: size(8);
    background-color: #ededed;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.19);
    border-radius: size(1);

    h2{
        font-size: size(8);
        font-weight: 500;
        margin: 0;
    }

    button{
        font-size: size(8);
        width: size(10);
        height: size(10);
        padding: 0;
        line-height: size(10);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dadada;
        transition: border-color .2s ease-in-out;
        border-radius: size(1);
        box-sizing: border-box;

        &:hover{
            border-color: #2b2d32;
        }
    }
}

.content{
    padding: size(15);
    border-radius: size(1);
}

.footer{
    padding: size(8);
    text-align: right;
    border-top: 1px solid #e5e5e5;
}