@import '../../theme';

.wrapper{
    & > span {
        padding: size(1) size(2);
        border-radius: size(2);
        background-color: rgba($opoint-blue, 0.2);
    }

    @each $num, $color in $types-pallet {      
        & > span[color="#{$num}"] {
            background-color: rgba(op-pallet($color), 0.2);
        }
    }
}