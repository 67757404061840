@import '../../theme';

.error{
    color: $opoint-warning-red;
    margin-top: size(1);
    display: inline-block;
}

.submitWrapper{
    display: flex;
    justify-content: flex-end;

    button{
        margin-right: 0;
        margin-top: size(2);
    }
}

.form{
    input, textarea, label{
        margin: size(2) 0;
    }
}