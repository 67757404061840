/* Main text area */
.rta-textarea {
  position: relative;
  padding: 3px 25px 4px 10px;
  border: 1px solid #dbdbdd;
  background-color: #fff;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  min-height: 36px;
}

.rta--rounded {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.rta-textarea--active {
  border-color: #72a4ce;
}

.rta-textarea--disabled {
  border-color: #dbdbdd;
  background-color: #f0f1f3;
}

.rta-textarea--no-suggestions-match {
  border-color: #f26d7d;
}

.rta-textarea__text {
  font-size: 13px;
  line-height: 13px;
  background-color: transparent;
  outline: 0;
}

.rta-textarea__clear {
  position: absolute;
  right: 5px;
  top: 4px;
  border: 0;
  background-color: transparent;
  z-index: 5;
  color: #337ab7;
}

.rta-textarea__clear:focus {
  color: #23527c;
  outline-style: dotted;
  outline-color: #337ab7;
  outline-width: 1px;
}

/* Filter list */
.rta-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: space-between;
}

.rta-suggestions {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #eff0f3;
  border-top: 0;
  margin-left: -1px;
  margin-right: -1px;
  z-index: 10;
}

.rta-suggestions__select-all-button:hover {
  cursor: copy;
}

.rta-filters__filter {
  height: 23px;
}

.rta-filters__filter:focus .filter-tag__tag {
  background: #78acd7;
}

.filter-tag--last {
  padding-right: 10px;
}

.rta-filters__filter:last-child .filter-tag--categorySeparator,
.rta-filters__filter:last-child filter-tag--filterSeparator {
  padding-right: 5px;
}

.rta-filters__empty {
  height: 23px;
  width: 1px;
  padding-left: 1px;
  overflow: hidden;
  display: inline-block;
}

.rta-suggestion--empty {
  padding: 3px 6px;
}

.rta-suggestion-active {
  background: #f4f4f4;
}
