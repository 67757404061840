@import '../../theme';

.list{
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;

    li{
        margin: size(2) size(4) size(2) 0;
        padding: size(3);
        border: 1px solid #ededed;
        border-radius: size(1);
    }
}