@import '../../theme.scss';

.wrapper {
    display: inline-flex;
    position: relative;
    overflow: hidden;
  }

 .wrapper::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $opoint-shadow;
    transition: transform 0.2s;
    transform: translateY(95%);
    pointer-events: none;
  }
  
  .wrapper:hover::before{
    transform: translateY(0%);
  }