@import '../../theme';

.wrapper{
    &.verticalCenter{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    [class^="op-icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: '#{$icomoon-font-family}' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
    
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    [class="op-icon-ifm-arrow-left"] {
        &:before {
            content: "\e949";
        }
    }

    [class="op-icon-ifm_arrow-right"] {
        &:before {
            content: "\e911";
        }
    }
  
    [class="op-icon-new-releases"] {
        &:before {
            content: "\e902"; 
        }
    }
    [class="op-icon-link"] {
        &:before {
            content: "\e90d"; 
        }
    }
    [class="op-icon-key"] {
        &:before {
            content: "\e90c"; 
        }
    }
    [class="op-icon-question"] {
        &:before {
            content: "\e90b"; 
        }
    }
    [class="op-icon-circle"] {
        &:before {
            content: "\e90a";
        }
    }
    [class="op-icon-interest-not-computed"] {
        &:before {
            content: "\e909";
        }
    }
    [class="op-icon-feed"] {
        &:before {
            content: "\e908";
        }
    }
    [class="op-icon-interest-high"] {
        &:before {
            content: "\e903";
        }
    }
    [class="op-icon-interest-low"] {
        &:before {
            content: "\e904";
        }
    }
    [class="op-icon-interest-mid"] {
        &:before {
            content: "\e905";
        }
    }
    [class="op-icon-interest-mid-high"] {
        &:before {
            content: "\e906";
        }
    }
    [class="op-icon-interest-mid-low"] {
        &:before {
            content: "\e907";
        }
    }
    [class="op-icon-tv"] {
        &:before {
            content: "\e900";
        }
    }
    [class="op-icon-radio"] {
        &:before {
            content: "\e901";
        }
    }
    [class="op-icon-expand"] {
        &:before {
            content: "\e801";
        }
    }
    [class="op-icon-minus"] {
        &:before {
            content: "\e800";
        }
    }
    [class="op-icon-circle-plus"] {
        &:before {
            content: "\e62a";
        }
    }
    [class="op-icon-circle-minus"] {
        &:before {
            content: "\e628";
        }
    }
    [class="op-icon-delete"] {
        &:before {
            content: "\e629";
        }
    }
    [class="op-icon-update"] {
        &:before {
            content: "\e624";
        }
    }
    [class="op-icon-download"] {
        &:before {
            content: "\e625";
        }
    }
    [class="op-icon-earth"] {
        &:before {
            content: "\e626";
        }
    }
    [class="op-icon-cancel"] {
        &:before {
            content: "\e627";
        }
    }
    [class="op-icon-alarm"] {
        &:before {
            content: "\e621";
        }
    }
    [class="op-icon-email"] {
        &:before {
            content: "\e622";
        }
    }
    [class="op-icon-mobil"] {
        &:before {
            content: "\e623";
        }
    }
    [class="op-icon-drag"] {
        &:before {
            content: "\e620";
        }
    }
    [class="op-icon-pencil"] {
        &:before {
            content: "\e61f";
        }
    }
    [class="op-icon-activity"] {
        &:before {
            content: "\e600";
        }
    }
    [class="op-icon-arrow-left"] {
        &:before {
            content: "\e601";
        }
    }
    [class="op-icon-eye"] {
        &:before {
            content: "\e602";
        }
    }
    [class="op-icon-arrow-down"] {
        &:before {
            content: "\e604";
        }
    }
    [class="op-icon-attachment"] {
        &:before {
            content: "\e605";
        }
    }
    [class="op-icon-bell-small"] {
        &:before {
            content: "\e606";
        }
    }
    [class="op-icon-bin"] {
        &:before {
            content: "\e607";
        }
    }
    [class="op-icon-calendar"] {
        &:before {
            content: "\e608";
        }
    }
    [class="op-icon-cross"] {
        &:before {
            content: "\e609";
        }
    }
    [class="op-icon-filters"] {
        &:before {
            content: "\e60a";
        }
    }
    [class="op-icon-bell"] {
        &:before {
            content: "\e60b";
        }
    }
    [class="op-icon-arrow-big-left"] {
        &:before {
            content: "\e60c";
        }
    }
    [class="op-icon-arrow-right"] {
        &:before {
            content: "\e60e";
        }
    }
    [class="op-icon-datepicker"] {
        &:before {
            content: "\e60f";
        }
    }
    [class="op-icon-file"] {
        &:before {
            content: "\e610";
        }
    }
    [class="op-icon-menu"] {
        &:before {
            content: "\e612";
        }
    }
    [class="op-icon-news"] {
        &:before {
            content: "\e613";
        }
    }
    [class="op-icon-plus"] {
        &:before {
            content: "\e614";
        }
    }
    [class="op-icon-gear"] {
        &:before {
            content: "\e615";
        }
    }
    [class="op-icon-statistics"] {
        &:before {
            content: "\e616";
        }
    }
    [class="op-icon-tag"] {
        &:before {
            content: "\e617";
        }
    }
    [class="op-icon-report"] {
        &:before {
            content: "\e618";
        }
    }
    [class="op-icon-images"] {
        &:before {
            content: "\e619";
        }
    }
    [class="op-icon-power"] {
        &:before {
            content: "\e61a";
        }
    }
    [class="op-icon-search"] {
        &:before {
            content: "\e61b";
        }
    }
    [class="op-icon-checked"] {
        &:before {
            content: "\e61c";
        }
    }
    [class="op-icon-striked-eye"] {
        &:before {
            content: "\e61d";
        }
    }
    [class="op-icon-three-dots"] {
        &:before {
            content: "\e61e";
        }
    }
    [class="op-icon-g-translate"] {
        &:before {
            content: "\e927";
        }
    }
    [class="op-icon-translate"] {
        &:before {
            content: "\e8e2";
        }
    }
    [class="op-icon-arrow-big-right"] {
        &:before {
            content: "\e611";
        }
    }
    [class="op-icon-hamburger"] {
        &:before {
            content: "\e62b";
        }
    }
    [class="op-icon-user"] {
        &:before {
            content: "\e90e";
        }
    }
    [class="op-icon-upload-arrow"] {
        &:before {
            content: "\e90f";
        }
    }
    [class="op-icon-filter"] {
        &:before {
            content: "\e910";
        }
    }
    [class="op-icon-notification"] {
        &:before {
            content:"\ea08";
        }
    }
    [class="op-icon-info"] {
        &:before {
            content: "\ea0c";
        }
    }
    [class="op-icon-list-numbered"] {
        &:before {
            content: "\e9b9";
        }
    }
    [class="op-icon-redo"] {
        &:before {
            content: "\e968";
        }
    }
    [class="op-icon-file-text"] {
        &:before {
            content: "\e926";
        }
    }
    [class="op-icon-page-break"] {
        &:before {
            content: "\ea68";
        }
    }
    [class="op-icon-pagebreak"] {
        &:before {
            content: "\ea6e";
        }
    }
    [class="op-icon-paragraph-left"] {
        &:before {
            content: "\ea77";
        }
    }
    [class="op-icon-paragraph-center"] {
        &:before {
            content: "\ea78";
        }
    }
    [class="op-icon-paragraph-right"] {
        &:before {
            content: "\ea79";
        }
    }
    [class="op-icon-location"] {
        &:before {
            content: "\e947";
        }
    }
    [class="op-icon-users"] {
        &:before {
            content: "\e972";
        }
    }
    [class="op-icon-user-plus"] {
        &:before {
            content: "\e973";
        }
    }
    [class="op-icon-eye-alt"] {
        &:before {
            content: "\e9ce";
        }
    }
    [class="op-icon-enter"] {
        &:before {
            content: "\ea13";
        }
    }
}