@import '../theme';

.modalBody{
    h3{
        margin: 0;
        font-size: size(7);
    }

    .settingTitle{
        font-size: size(5);
        margin: 0 0 size(4) 0;
    }

    .settingWrapper{
        margin: size(4) 0;
        padding: size(4);
        border: 1px solid #ededed;
    }

    .childrenWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .full{
            width: 100%;
        }

        .half{
            width: 50%;
        }
    }

    hr{
        display: block;
        width: 100%;
        margin: size(5) 0;
    }

    .submitWrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button{
            margin: 0;
        }
    }
}