@import '../../theme';

$dotSize: size(4);

.loader {
    font-size: 0;
    margin: 0 auto 0;
    padding: size(8) 0;
    width: size(20);
    text-align: center;

    & > div:nth-of-type(1) {
        animation-delay: -0.32s;
    }
    & > div:nth-of-type(2) {
        animation-delay: -0.16s;
    }

    & > div {
        width: $dotSize;
        height: $dotSize;
        margin-right: size(1);
        margin-left: size(1);
        background-color: #333;
        
        border-radius: 100%;
        display: inline-block;
        animation: bounce 0.8s infinite ease-in-out both;
    }
}

@keyframes bounce{
    0%, 80%, 100% {
        transform: scale(0.6);
    }
    40% {
        transform: scale(1);
    }
}