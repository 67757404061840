@import '../../theme';

.tooltipContainer{
    div[class*="inner"]{
        background: white;
        border-radius: 3px;
        box-shadow: 0.7px 0.8px 8px rgba(0, 0, 0, 0.22);
        min-width: 300px;
        color: #262626;
        padding: 10px 0;
    }
    div[class*="arrow"]{
        border-top-color: white !important;
    }
}

.tooltipContentWrapper{
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    strong{
        margin: 0 20px;
    }

    hr{
        margin: 5px 0;
        width: 100%;
        height: 1px;
        background-color: $opoint-bg-gray;
    }

    ul{
        margin: 0 20px;
        padding: 0 0 0 15px;
    }
}