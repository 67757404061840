.auto-item {
  display: block;
  padding: 3px 12px;
  cursor: pointer;
}

.auto-item:hover .auto-item__name,
.auto-item:hover .auto-item__url {
  color: #72a4ce;
}

.auto-item__type {
  color: #a6a6a6;
  margin-right: 5px;
}
.auto-item__name {
  color: #333;
}

.auto-item__url {
  font-style: italic;
}

.is-selected .auto-item__name {
  font-weight: 700;
}
